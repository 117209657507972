<template>
  <v-container>

    <NavBar v-if="loadMounted == true" :step="3.4" :typePlan="userData.typePlan"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen < 800">
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Planes
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 12"
            step="5">Datos</v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="9">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <h3 class="text-center labelExcelente">
                    ¡Excelente! <br> Podemos continuar con el proceso de proteger tu salud
                  </h3>

                  <br><br>

                  <h3 class="text-center labelSubExcelente">
                    Recuerda que si no declaras correctamente tu salud u omites información, podría afectar el cobro del
                    seguro si debes usarlo.
                  </h3>

                  <br>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 300px;"
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import Titulo from "./Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import CryptoJS from 'crypto-js';

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    loadMounted: false,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    valid: false,
    stepperColor: '#4F74E3',
    screen: window.screen.width,
    stepId: null,
    components: { Titulo },
    e1: 9,
    questionRules: [(v) => !!v || "Debe seleccionar una opción"],
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const userDataAux = localStorage.getItem('userData');

    if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
    } else {
      this.vista = 'vista'
    }

    this.loadMounted = true;
  },
  methods: {
    back() {
      this.$router.push({ path: '/og/dps2' });
    },
    validate() {

      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      localStorage.setItem('userData', encryptedUserDataLocal);

      if (this.userData.typePlan == 'Individual') {
        this.$router.push({ path: '/og/datos-contratante' });
      } else {
        this.$router.push({ path: '/og/datos-beneficiarios' });
      }
    },
  }
};

</script>

<style lang="scss">
.labelNumLlamar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 50px;
  top: 175px;
  text-align: center;
}

.labelSuperior {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #232323;
  opacity: 0.75;
  height: 103px;
}

.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.labelQuestion1 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #4040CF;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  justify-content: left;
}

.labelTexto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  text-align: justify;
}

.labelTexto-negrita {
  font-family: Helvetica;
  font-style: italic;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: #232323;
  height: 138px;
  text-align: justify;
  hyphens: auto;
}

.labelBeneficioss {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 150px;
  text-align: justify;
}

.labelExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.labelNoExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelnoSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-data {
  max-width: 85%;
  align-items: center;
  // display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  max-width: 800px;
  background: #F3F3F3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

h1 {
  font-weight: 500;
  text-align: center;
  color: #464e71;
  margin-bottom: 2rem;
}

h2 {
  font-weight: 500;
  color: #464e71;
  margin-bottom: 1rem;
}

p {
  text-align: center;
}

.back-container {
  width: 100%;
  align-items: flex-start;

  .back-button {


    &:hover {
      opacity: 1;
    }
  }
}

.button-container {
  text-align: center;
}

.forecast-selector {
  text-transform: uppercase;
  font-weight: 600;
  color: #464e71;
}

.v-btn__content {
  padding: 0.75rem;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: center;
  margin: auto;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div div.col.vista div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message.message-transition-enter-to {
  padding-bottom: 10%;
  text-align: center;
  min-width: 100px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}
</style>